import { createStore } from 'vuex';

/* global toastr */

const store = createStore({
  state() {
    return {
      taskType: 'elasticity',
      jsonData: {
        width: 1200,
        height: 480,
        axes: [
          {
            bbox: [0.3585, 0.10999999999999999, 0.3080000000000001, 0.77],
            xlim: [-0.5, 10.5],
            ylim: [-0.5, 10.5],
            xdomain: [-0.5, 10.5],
            ydomain: [-0.5, 10.5],
            xscale: 'linear',
            yscale: 'linear',
            axes: [
              {
                position: 'bottom',
                nticks: 8,
                tickvalues: null,
                tickformat_formatter: '',
                tickformat: null,
                scale: 'linear',
                fontsize: 10,
                grid: {
                  gridOn: false,
                },
                visible: true,
              },
              {
                position: 'left',
                nticks: 8,
                tickvalues: null,
                tickformat_formatter: '',
                tickformat: null,
                scale: 'linear',
                fontsize: 10,
                grid: {
                  gridOn: false,
                },
                visible: true,
              },
            ],
            axesbg: '#FFFFFF',
            axesbgalpha: null,
            zoomable: true,
            id: 'el185842141571397328',
            lines: [
              {
                data: 'data01',
                xindex: 0,
                yindex: 1,
                coordinates: 'data',
                id: 'el185842141571126928',
                color: '#1F77B4',
                linewidth: 1.5,
                dasharray: 'none',
                alpha: 1,
                zorder: 2,
                drawstyle: 'default',
              },
              {
                data: 'data01',
                xindex: 2,
                yindex: 0,
                coordinates: 'data',
                id: 'el185842141571125200',
                color: '#FF7F0E',
                linewidth: 1.5,
                dasharray: 'none',
                alpha: 1,
                zorder: 2,
                drawstyle: 'default',
              },
              {
                data: 'data01',
                xindex: 3,
                yindex: 2,
                coordinates: 'data',
                id: 'el185842141571124944',
                color: '#2CA02C',
                linewidth: 1.5,
                dasharray: 'none',
                alpha: 1,
                zorder: 2,
                drawstyle: 'default',
              },
              {
                data: 'data01',
                xindex: 4,
                yindex: 2,
                coordinates: 'data',
                id: 'el185842141571132752',
                color: '#D62728',
                linewidth: 1.5,
                dasharray: 'none',
                alpha: 1,
                zorder: 2,
                drawstyle: 'default',
              },
              {
                data: 'data01',
                xindex: 1,
                yindex: 5,
                coordinates: 'data',
                id: 'el185842141571134416',
                color: '#9467BD',
                linewidth: 1.5,
                dasharray: 'none',
                alpha: 1,
                zorder: 2,
                drawstyle: 'default',
              },
              {
                data: 'data01',
                xindex: 1,
                yindex: 6,
                coordinates: 'data',
                id: 'el185842141570870224',
                color: '#8C564B',
                linewidth: 1.5,
                dasharray: 'none',
                alpha: 1,
                zorder: 2,
                drawstyle: 'default',
              },
              {
                data: 'data02',
                xindex: 0,
                yindex: 1,
                coordinates: 'data',
                id: 'el185842141570860048',
                color: '#E377C2',
                linewidth: 1.5,
                dasharray: 'none',
                alpha: 1,
                zorder: 2,
                drawstyle: 'default',
              },
              {
                data: 'data03',
                xindex: 0,
                yindex: 1,
                coordinates: 'data',
                id: 'el185842141570863056',
                color: '#7F7F7F',
                linewidth: 1.5,
                dasharray: 'none',
                alpha: 1,
                zorder: 2,
                drawstyle: 'default',
              },
            ],
            paths: [
              {
                data: 'data07',
                xindex: 0,
                yindex: 1,
                coordinates: 'axes',
                pathcodes: ['M', 'L', 'S', 'L', 'S', 'L', 'S', 'L', 'S', 'Z'],
                id: 'el185842141570627280',
                dasharray: 'none',
                alpha: 0.8,
                facecolor: 'rgba(255, 255, 255, 0.8)',
                edgecolor: 'rgba(204, 204, 204, 0.8)',
                edgewidth: 1,
                zorder: 1000000,
              },
            ],
            markers: [
              {
                data: 'data06',
                xindex: 0,
                yindex: 1,
                coordinates: 'axes',
                id: 'el185842141571441872pts',
                facecolor: '#00007F',
                edgecolor: '#00007F',
                edgewidth: 1,
                alpha: 1,
                zorder: 1000002,
                markerpath: [
                  [
                    [0, 3],
                    [0.7956093000000001, 3],
                    [1.5587396123545605, 2.683901074764725],
                    [2.121320343559643, 2.121320343559643],
                    [2.683901074764725, 1.5587396123545605],
                    [3, 0.7956093000000001],
                    [3, 0],
                    [3, -0.7956093000000001],
                    [2.683901074764725, -1.5587396123545605],
                    [2.121320343559643, -2.121320343559643],
                    [1.5587396123545605, -2.683901074764725],
                    [0.7956093000000001, -3],
                    [0, -3],
                    [-0.7956093000000001, -3],
                    [-1.5587396123545605, -2.683901074764725],
                    [-2.121320343559643, -2.121320343559643],
                    [-2.683901074764725, -1.5587396123545605],
                    [-3, -0.7956093000000001],
                    [-3, 0],
                    [-3, 0.7956093000000001],
                    [-2.683901074764725, 1.5587396123545605],
                    [-2.121320343559643, 2.121320343559643],
                    [-1.5587396123545605, 2.683901074764725],
                    [-0.7956093000000001, 3],
                    [0, 3],
                  ],
                  ['M', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'Z'],
                ],
              },
              {
                data: 'data06',
                xindex: 0,
                yindex: 2,
                coordinates: 'axes',
                id: 'el185842141571445328pts',
                facecolor: '#7F0000',
                edgecolor: '#7F0000',
                edgewidth: 1,
                alpha: 1,
                zorder: 1000002,
                markerpath: [
                  [
                    [0, 3],
                    [0.7956093000000001, 3],
                    [1.5587396123545605, 2.683901074764725],
                    [2.121320343559643, 2.121320343559643],
                    [2.683901074764725, 1.5587396123545605],
                    [3, 0.7956093000000001],
                    [3, 0],
                    [3, -0.7956093000000001],
                    [2.683901074764725, -1.5587396123545605],
                    [2.121320343559643, -2.121320343559643],
                    [1.5587396123545605, -2.683901074764725],
                    [0.7956093000000001, -3],
                    [0, -3],
                    [-0.7956093000000001, -3],
                    [-1.5587396123545605, -2.683901074764725],
                    [-2.121320343559643, -2.121320343559643],
                    [-2.683901074764725, -1.5587396123545605],
                    [-3, -0.7956093000000001],
                    [-3, 0],
                    [-3, 0.7956093000000001],
                    [-2.683901074764725, 1.5587396123545605],
                    [-2.121320343559643, 2.121320343559643],
                    [-1.5587396123545605, 2.683901074764725],
                    [-0.7956093000000001, 3],
                    [0, 3],
                  ],
                  ['M', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'Z'],
                ],
              },
            ],
            texts: [
              {
                text: 'Line1',
                position: [5, 0],
                coordinates: 'data',
                h_anchor: 'start',
                v_baseline: 'auto',
                rotation: 0,
                fontsize: 10,
                color: '#000000',
                alpha: 1,
                zorder: 3,
                id: 'el185842141570983824',
              },
              {
                text: 'Line2',
                position: [10, 5],
                coordinates: 'data',
                h_anchor: 'start',
                v_baseline: 'auto',
                rotation: 0,
                fontsize: 10,
                color: '#000000',
                alpha: 1,
                zorder: 3,
                id: 'el185842141570710544',
              },
              {
                text: 'Line3',
                position: [5.5, 10],
                coordinates: 'data',
                h_anchor: 'start',
                v_baseline: 'auto',
                rotation: 0,
                fontsize: 10,
                color: '#000000',
                alpha: 1,
                zorder: 3,
                id: 'el185842141570965136',
              },
              {
                text: 'Line4',
                position: [0.5, 10],
                coordinates: 'data',
                h_anchor: 'start',
                v_baseline: 'auto',
                rotation: 0,
                fontsize: 10,
                color: '#000000',
                alpha: 1,
                zorder: 3,
                id: 'el185842141571397072',
              },
              {
                text: 'Line5',
                position: [0, 9.5],
                coordinates: 'data',
                h_anchor: 'start',
                v_baseline: 'auto',
                rotation: 0,
                fontsize: 10,
                color: '#000000',
                alpha: 1,
                zorder: 3,
                id: 'el185842141571130896',
              },
              {
                text: 'Line6',
                position: [0, 4.5],
                coordinates: 'data',
                h_anchor: 'start',
                v_baseline: 'auto',
                rotation: 0,
                fontsize: 10,
                color: '#000000',
                alpha: 1,
                zorder: 3,
                id: 'el185842141571123280',
              },
              {
                text: 'Line7',
                position: [0.5, 9],
                coordinates: 'data',
                h_anchor: 'start',
                v_baseline: 'auto',
                rotation: 0,
                fontsize: 10,
                color: '#000000',
                alpha: 1,
                zorder: 3,
                id: 'el185842141570560144',
              },
              {
                text: 'Line8',
                position: [1, 9.5],
                coordinates: 'data',
                h_anchor: 'start',
                v_baseline: 'auto',
                rotation: 0,
                fontsize: 10,
                color: '#000000',
                alpha: 1,
                zorder: 3,
                id: 'el185842141570862992',
              },
              {
                text: 'X Axis',
                position: [0.5, -0.07921476671476674],
                coordinates: 'axes',
                h_anchor: 'middle',
                v_baseline: 'hanging',
                rotation: 0,
                fontsize: 10,
                color: '#000000',
                alpha: 1,
                zorder: 3,
                id: 'el185842141571393808',
              },
              {
                text: 'Y Axis',
                position: [-0.08902266714766705, 0.5],
                coordinates: 'axes',
                h_anchor: 'middle',
                v_baseline: 'auto',
                rotation: -90,
                fontsize: 10,
                color: '#000000',
                alpha: 1,
                zorder: 3,
                id: 'el185842141570764560',
              },
              {
                text: 'Surface1',
                position: [1.1590392015392015, 0.9364177489177489],
                coordinates: 'axes',
                h_anchor: 'start',
                v_baseline: 'auto',
                rotation: 0,
                fontsize: 10,
                color: '#000000',
                alpha: 1,
                zorder: 1000003,
                id: 'el185842141571437776',
              },
              {
                text: 'Surface2',
                position: [1.1590392015392015, 0.8797498797498798],
                coordinates: 'axes',
                h_anchor: 'start',
                v_baseline: 'auto',
                rotation: 0,
                fontsize: 10,
                color: '#000000',
                alpha: 1,
                zorder: 1000003,
                id: 'el185842141571447248',
              },
            ],
            collections: [
              {
                offsets: 'data04',
                xindex: 0,
                yindex: 1,
                paths: [
                  [
                    [
                      [1.4422772368759, 6.1712259983965],
                      [2.2280785087258, 5.6101840621225],
                      [2.1665102814481, 6.5418406423712],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.2280785087258, 5.6101840621225],
                      [3.0138797805757, 5.0491421258485],
                      [2.952311553298, 5.9807987060972],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.952311553298, 5.9807987060972],
                      [2.8907433260204, 6.9124552863459],
                      [2.1665102814481, 6.5418406423712],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.2280785087258, 5.6101840621225],
                      [2.952311553298, 5.9807987060972],
                      [2.1665102814481, 6.5418406423712],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0, 7.2],
                      [0.42826925372461, 7.6864762724775],
                      [0, 8.1],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.42826925372461, 7.6864762724775],
                      [0.85653850744922, 8.172952544955],
                      [0.42826925372461, 8.5864762724775],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.42826925372461, 8.5864762724775],
                      [0, 9],
                      [0, 8.1],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.42826925372461, 7.6864762724775],
                      [0.42826925372461, 8.5864762724775],
                      [0, 8.1],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.0138797805757, 5.0491421258485],
                      [3.4594845441343, 5.5023100432411],
                      [2.952311553298, 5.9807987060972],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.4594845441343, 5.5023100432411],
                      [3.9050893076928, 5.9554779606337],
                      [3.3979163168566, 6.4339666234898],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.3979163168566, 6.4339666234898],
                      [2.8907433260204, 6.9124552863459],
                      [2.952311553298, 5.9807987060972],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.4594845441343, 5.5023100432411],
                      [3.3979163168566, 6.4339666234898],
                      [2.952311553298, 5.9807987060972],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.5330416233482, 8.5789765738768],
                      [2.7760712047888, 8.932297579332],
                      [2.713847755626, 8.42950028797],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.7760712047888, 8.932297579332],
                      [2.0191007862293, 9.2856185847872],
                      [1.9568773370665, 8.7828212934252],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.9568773370665, 8.7828212934252],
                      [1.8946538879037, 8.2800240020632],
                      [2.713847755626, 8.42950028797],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.7760712047888, 8.932297579332],
                      [1.9568773370665, 8.7828212934252],
                      [2.713847755626, 8.42950028797],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1, 10],
                      [1.5095503931147, 9.6428092923936],
                      [1.9, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.5095503931147, 9.6428092923936],
                      [2.0191007862293, 9.2856185847872],
                      [2.4095503931147, 9.6428092923936],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.4095503931147, 9.6428092923936],
                      [2.8, 10],
                      [1.9, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.5095503931147, 9.6428092923936],
                      [2.4095503931147, 9.6428092923936],
                      [1.9, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1, 9],
                      [0.92826925372461, 8.5864762724775],
                      [1.4473269439518, 8.6400120010316],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.92826925372461, 8.5864762724775],
                      [0.85653850744922, 8.172952544955],
                      [1.3755961976764, 8.2264882735091],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.3755961976764, 8.2264882735091],
                      [1.8946538879037, 8.2800240020632],
                      [1.4473269439518, 8.6400120010316],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.92826925372461, 8.5864762724775],
                      [1.3755961976764, 8.2264882735091],
                      [1.4473269439518, 8.6400120010316],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.5264809097078, 5.8633144759761],
                      [5.6653918172609, 6.3793783697738],
                      [5.7189561545345, 5.4140933213726],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.6653918172609, 6.3793783697738],
                      [4.804302724814, 6.8954422635715],
                      [4.8578670620876, 5.9301572151703],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.8578670620876, 5.9301572151703],
                      [4.9114313993612, 4.9648721667691],
                      [5.7189561545345, 5.4140933213726],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.6653918172609, 6.3793783697738],
                      [4.8578670620876, 5.9301572151703],
                      [5.7189561545345, 5.4140933213726],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.4628850403151, 4.4021560435473],
                      [2.2383824104454, 4.7256490846979],
                      [1.4525811385955, 5.2866910209719],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.2383824104454, 4.7256490846979],
                      [3.0138797805757, 5.0491421258485],
                      [2.2280785087258, 5.6101840621225],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.2280785087258, 5.6101840621225],
                      [1.4422772368759, 6.1712259983965],
                      [1.4525811385955, 5.2866910209719],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.2383824104454, 4.7256490846979],
                      [2.2280785087258, 5.6101840621225],
                      [1.4525811385955, 5.2866910209719],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.7357535270392, 3.9518211557815],
                      [6.7896803499662, 2.9187434805735],
                      [7.5261525924988, 3.4862940231838],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.7896803499662, 2.9187434805735],
                      [6.8436071728932, 1.8856658053655],
                      [7.5800794154258, 2.4532163479758],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.5800794154258, 2.4532163479758],
                      [8.3165516579584, 3.0207668905861],
                      [7.5261525924988, 3.4862940231838],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.7896803499662, 2.9187434805735],
                      [7.5800794154258, 2.4532163479758],
                      [7.5261525924988, 3.4862940231838],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.8, 10],
                      [2.4095503931147, 9.6428092923936],
                      [3.1665208116741, 9.2894882869384],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.4095503931147, 9.6428092923936],
                      [2.0191007862293, 9.2856185847872],
                      [2.7760712047888, 8.932297579332],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.7760712047888, 8.932297579332],
                      [3.5330416233482, 8.5789765738768],
                      [3.1665208116741, 9.2894882869384],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.4095503931147, 9.6428092923936],
                      [2.7760712047888, 8.932297579332],
                      [3.1665208116741, 9.2894882869384],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.8946538879037, 8.2800240020632],
                      [1.641346268623, 7.8323813623876],
                      [2.392698606962, 7.5962396442045],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.641346268623, 7.8323813623876],
                      [1.3880386493424, 7.3847387227119],
                      [2.1393909876814, 7.1485970045289],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.1393909876814, 7.1485970045289],
                      [2.8907433260204, 6.9124552863459],
                      [2.392698606962, 7.5962396442045],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.641346268623, 7.8323813623876],
                      [2.1393909876814, 7.1485970045289],
                      [2.392698606962, 7.5962396442045],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.8, 10],
                      [3.1665208116741, 9.2894882869384],
                      [3.7, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.1665208116741, 9.2894882869384],
                      [3.5330416233482, 8.5789765738768],
                      [4.0665208116741, 9.2894882869384],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.0665208116741, 9.2894882869384],
                      [4.6, 10],
                      [3.7, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.1665208116741, 9.2894882869384],
                      [4.0665208116741, 9.2894882869384],
                      [3.7, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.5264809097078, 5.8633144759761],
                      [6.4875958388, 6.6262834844193],
                      [5.6653918172609, 6.3793783697738],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.4875958388, 6.6262834844193],
                      [6.4487107678922, 7.3892524928625],
                      [5.6265067463531, 7.142347378217],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.6265067463531, 7.142347378217],
                      [4.804302724814, 6.8954422635715],
                      [5.6653918172609, 6.3793783697738],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.4875958388, 6.6262834844193],
                      [5.6265067463531, 7.142347378217],
                      [5.6653918172609, 6.3793783697738],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.6, 10],
                      [4.0665208116741, 9.2894882869384],
                      [5.0507606610561, 9.2707550736899],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.0665208116741, 9.2894882869384],
                      [3.5330416233482, 8.5789765738768],
                      [4.5172814727302, 8.5602433606283],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.5172814727302, 8.5602433606283],
                      [5.5015213221121, 8.5415101473798],
                      [5.0507606610561, 9.2707550736899],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.0665208116741, 9.2894882869384],
                      [4.5172814727302, 8.5602433606283],
                      [5.0507606610561, 9.2707550736899],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.7357535270392, 3.9518211557815],
                      [6.0843687137726, 3.5325576738242],
                      [6.7896803499662, 2.9187434805735],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.0843687137726, 3.5325576738242],
                      [5.4329839005059, 3.113294191867],
                      [6.1382955366996, 2.4994799986163],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.1382955366996, 2.4994799986163],
                      [6.8436071728932, 1.8856658053655],
                      [6.7896803499662, 2.9187434805735],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.0843687137726, 3.5325576738242],
                      [6.1382955366996, 2.4994799986163],
                      [6.7896803499662, 2.9187434805735],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.5330416233482, 8.5789765738768],
                      [2.713847755626, 8.42950028797],
                      [3.2118924746843, 7.7457159301113],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.713847755626, 8.42950028797],
                      [1.8946538879037, 8.2800240020632],
                      [2.392698606962, 7.5962396442045],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.392698606962, 7.5962396442045],
                      [2.8907433260204, 6.9124552863459],
                      [3.2118924746843, 7.7457159301113],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.713847755626, 8.42950028797],
                      [2.392698606962, 7.5962396442045],
                      [3.2118924746843, 7.7457159301113],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.5330416233482, 8.5789765738768],
                      [4.1686721740811, 7.7372094187242],
                      [4.5172814727302, 8.5602433606283],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.1686721740811, 7.7372094187242],
                      [4.804302724814, 6.8954422635715],
                      [5.1529120234631, 7.7184762054756],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.1529120234631, 7.7184762054756],
                      [5.5015213221121, 8.5415101473798],
                      [4.5172814727302, 8.5602433606283],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.1686721740811, 7.7372094187242],
                      [5.1529120234631, 7.7184762054756],
                      [4.5172814727302, 8.5602433606283],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.5330416233482, 8.5789765738768],
                      [3.2118924746843, 7.7457159301113],
                      [4.1686721740811, 7.7372094187242],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.2118924746843, 7.7457159301113],
                      [2.8907433260204, 6.9124552863459],
                      [3.8475230254172, 6.9039487749587],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.8475230254172, 6.9039487749587],
                      [4.804302724814, 6.8954422635715],
                      [4.1686721740811, 7.7372094187242],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.2118924746843, 7.7457159301113],
                      [3.8475230254172, 6.9039487749587],
                      [4.1686721740811, 7.7372094187242],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [10, 8],
                      [9.0881239667028, 7.522738155056],
                      [10, 7],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.0881239667028, 7.522738155056],
                      [8.1762479334055, 7.0454763101119],
                      [9.0881239667028, 6.522738155056],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.0881239667028, 6.522738155056],
                      [10, 6],
                      [10, 7],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.0881239667028, 7.522738155056],
                      [9.0881239667028, 6.522738155056],
                      [10, 7],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.8946538879037, 8.2800240020632],
                      [1.9568773370665, 8.7828212934252],
                      [1.4473269439518, 8.6400120010316],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.9568773370665, 8.7828212934252],
                      [2.0191007862293, 9.2856185847872],
                      [1.5095503931147, 9.1428092923936],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.5095503931147, 9.1428092923936],
                      [1, 9],
                      [1.4473269439518, 8.6400120010316],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.9568773370665, 8.7828212934252],
                      [1.5095503931147, 9.1428092923936],
                      [1.4473269439518, 8.6400120010316],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2750181831194, 4.9827086264627],
                      [7.4007495464136, 5.4230115512194],
                      [7.5053858550793, 4.4672648911221],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.4007495464136, 5.4230115512194],
                      [6.5264809097078, 5.8633144759761],
                      [6.6311172183735, 4.9075678158788],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.6311172183735, 4.9075678158788],
                      [6.7357535270392, 3.9518211557815],
                      [7.5053858550793, 4.4672648911221],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.4007495464136, 5.4230115512194],
                      [6.6311172183735, 4.9075678158788],
                      [7.5053858550793, 4.4672648911221],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.5264809097078, 5.8633144759761],
                      [5.7189561545345, 5.4140933213726],
                      [6.6311172183735, 4.9075678158788],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.7189561545345, 5.4140933213726],
                      [4.9114313993612, 4.9648721667691],
                      [5.8235924632002, 4.4583466612753],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.8235924632002, 4.4583466612753],
                      [6.7357535270392, 3.9518211557815],
                      [6.6311172183735, 4.9075678158788],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.7189561545345, 5.4140933213726],
                      [5.8235924632002, 4.4583466612753],
                      [6.6311172183735, 4.9075678158788],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.8939677025396, 1.6397718215336],
                      [3.9019440863061, 1.7143915806936],
                      [4.4469838512698, 0.81988591076681],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.9019440863061, 1.7143915806936],
                      [2.9099204700726, 1.7890113398535],
                      [3.4549602350363, 0.89450566992675],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.4549602350363, 0.89450566992675],
                      [4, 0],
                      [4.4469838512698, 0.81988591076681],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.9019440863061, 1.7143915806936],
                      [3.4549602350363, 0.89450566992675],
                      [4.4469838512698, 0.81988591076681],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2750181831194, 4.9827086264627],
                      [7.5053858550793, 4.4672648911221],
                      [8.2957849205389, 4.0017377585244],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.5053858550793, 4.4672648911221],
                      [6.7357535270392, 3.9518211557815],
                      [7.5261525924988, 3.4862940231838],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.5261525924988, 3.4862940231838],
                      [8.3165516579584, 3.0207668905861],
                      [8.2957849205389, 4.0017377585244],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.5053858550793, 4.4672648911221],
                      [7.5261525924988, 3.4862940231838],
                      [8.2957849205389, 4.0017377585244],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [10, 6],
                      [9.1375090915597, 5.4913543132313],
                      [10, 5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.1375090915597, 5.4913543132313],
                      [8.2750181831194, 4.9827086264627],
                      [9.1375090915597, 4.4913543132313],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.1375090915597, 4.4913543132313],
                      [10, 4],
                      [10, 5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.1375090915597, 5.4913543132313],
                      [9.1375090915597, 4.4913543132313],
                      [10, 5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6, 0],
                      [5.4469838512698, 0.81988591076681],
                      [5, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.4469838512698, 0.81988591076681],
                      [4.8939677025396, 1.6397718215336],
                      [4.4469838512698, 0.81988591076681],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.4469838512698, 0.81988591076681],
                      [4, 0],
                      [5, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.4469838512698, 0.81988591076681],
                      [4.4469838512698, 0.81988591076681],
                      [5, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2750181831194, 4.9827086264627],
                      [8.2957849205389, 4.0017377585244],
                      [9.1375090915597, 4.4913543132313],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2957849205389, 4.0017377585244],
                      [8.3165516579584, 3.0207668905861],
                      [9.1582758289792, 3.510383445293],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.1582758289792, 3.510383445293],
                      [10, 4],
                      [9.1375090915597, 4.4913543132313],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2957849205389, 4.0017377585244],
                      [9.1582758289792, 3.510383445293],
                      [9.1375090915597, 4.4913543132313],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4, 0],
                      [3.4549602350363, 0.89450566992675],
                      [3, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.4549602350363, 0.89450566992675],
                      [2.9099204700726, 1.7890113398535],
                      [2.4549602350363, 0.89450566992675],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.4549602350363, 0.89450566992675],
                      [2, 0],
                      [3, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.4549602350363, 0.89450566992675],
                      [2.4549602350363, 0.89450566992675],
                      [3, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [10, 6],
                      [9.0881239667028, 6.522738155056],
                      [9.1375090915597, 5.4913543132313],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.0881239667028, 6.522738155056],
                      [8.1762479334055, 7.0454763101119],
                      [8.2256330582624, 6.0140924682873],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2256330582624, 6.0140924682873],
                      [8.2750181831194, 4.9827086264627],
                      [9.1375090915597, 5.4913543132313],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.0881239667028, 6.522738155056],
                      [8.2256330582624, 6.0140924682873],
                      [9.1375090915597, 5.4913543132313],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2750181831194, 4.9827086264627],
                      [8.2256330582624, 6.0140924682873],
                      [7.4007495464136, 5.4230115512194],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2256330582624, 6.0140924682873],
                      [8.1762479334055, 7.0454763101119],
                      [7.3513644215567, 6.454395393044],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.3513644215567, 6.454395393044],
                      [6.5264809097078, 5.8633144759761],
                      [7.4007495464136, 5.4230115512194],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2256330582624, 6.0140924682873],
                      [7.3513644215567, 6.454395393044],
                      [7.4007495464136, 5.4230115512194],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [10, 4],
                      [9.1582758289792, 3.510383445293],
                      [10, 3],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.1582758289792, 3.510383445293],
                      [8.3165516579584, 3.0207668905861],
                      [9.1582758289792, 2.510383445293],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.1582758289792, 2.510383445293],
                      [10, 2],
                      [10, 3],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.1582758289792, 3.510383445293],
                      [9.1582758289792, 2.510383445293],
                      [10, 3],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.8907433260204, 6.9124552863459],
                      [2.1393909876814, 7.1485970045289],
                      [2.1665102814481, 6.5418406423712],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.1393909876814, 7.1485970045289],
                      [1.3880386493424, 7.3847387227119],
                      [1.4151579431092, 6.7779823605542],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.4151579431092, 6.7779823605542],
                      [1.4422772368759, 6.1712259983965],
                      [2.1665102814481, 6.5418406423712],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.1393909876814, 7.1485970045289],
                      [1.4151579431092, 6.7779823605542],
                      [2.1665102814481, 6.5418406423712],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.6, 10],
                      [5.0507606610561, 9.2707550736899],
                      [5.5, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.0507606610561, 9.2707550736899],
                      [5.5015213221121, 8.5415101473798],
                      [5.9507606610561, 9.2707550736899],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.9507606610561, 9.2707550736899],
                      [6.4, 10],
                      [5.5, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.0507606610561, 9.2707550736899],
                      [5.9507606610561, 9.2707550736899],
                      [5.5, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0, 3.6],
                      [0.73144252015757, 4.0010780217736],
                      [0, 4.5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.73144252015757, 4.0010780217736],
                      [1.4628850403151, 4.4021560435473],
                      [0.73144252015757, 4.9010780217736],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.73144252015757, 4.9010780217736],
                      [0, 5.4],
                      [0, 4.5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.73144252015757, 4.0010780217736],
                      [0.73144252015757, 4.9010780217736],
                      [0, 4.5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8, 0],
                      [7.4218035864466, 0.94283290268276],
                      [7, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.4218035864466, 0.94283290268276],
                      [6.8436071728932, 1.8856658053655],
                      [6.4218035864466, 0.94283290268276],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.4218035864466, 0.94283290268276],
                      [6, 0],
                      [7, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.4218035864466, 0.94283290268276],
                      [6.4218035864466, 0.94283290268276],
                      [7, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.804302724814, 6.8954422635715],
                      [4.3546960162534, 6.4254601121026],
                      [4.8578670620876, 5.9301572151703],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.3546960162534, 6.4254601121026],
                      [3.9050893076928, 5.9554779606337],
                      [4.408260353527, 5.4601750637014],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.408260353527, 5.4601750637014],
                      [4.9114313993612, 4.9648721667691],
                      [4.8578670620876, 5.9301572151703],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.3546960162534, 6.4254601121026],
                      [4.408260353527, 5.4601750637014],
                      [4.8578670620876, 5.9301572151703],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.8939677025396, 1.6397718215336],
                      [4.4447593332747, 2.4798763925424],
                      [3.9019440863061, 1.7143915806936],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.4447593332747, 2.4798763925424],
                      [3.9955509640098, 3.3199809635511],
                      [3.4527357170412, 2.5544961517023],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.4527357170412, 2.5544961517023],
                      [2.9099204700726, 1.7890113398535],
                      [3.9019440863061, 1.7143915806936],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.4447593332747, 2.4798763925424],
                      [3.4527357170412, 2.5544961517023],
                      [3.9019440863061, 1.7143915806936],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.4628850403151, 4.4021560435473],
                      [1.4525811385955, 5.2866910209719],
                      [0.73144252015757, 4.9010780217736],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.4525811385955, 5.2866910209719],
                      [1.4422772368759, 6.1712259983965],
                      [0.72113861843797, 5.7856129991983],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.72113861843797, 5.7856129991983],
                      [0, 5.4],
                      [0.73144252015757, 4.9010780217736],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.4525811385955, 5.2866910209719],
                      [0.72113861843797, 5.7856129991983],
                      [0.73144252015757, 4.9010780217736],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1, 9],
                      [1.5095503931147, 9.1428092923936],
                      [1, 9.5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.5095503931147, 9.1428092923936],
                      [2.0191007862293, 9.2856185847872],
                      [1.5095503931147, 9.6428092923936],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.5095503931147, 9.6428092923936],
                      [1, 10],
                      [1, 9.5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.5095503931147, 9.1428092923936],
                      [1.5095503931147, 9.6428092923936],
                      [1, 9.5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6, 0],
                      [6.4218035864466, 0.94283290268276],
                      [5.4469838512698, 0.81988591076681],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.4218035864466, 0.94283290268276],
                      [6.8436071728932, 1.8856658053655],
                      [5.8687874377164, 1.7627188134496],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.8687874377164, 1.7627188134496],
                      [4.8939677025396, 1.6397718215336],
                      [5.4469838512698, 0.81988591076681],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.4218035864466, 0.94283290268276],
                      [5.8687874377164, 1.7627188134496],
                      [5.4469838512698, 0.81988591076681],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.4, 10],
                      [6.8175004747108, 9.3002596336365],
                      [7.3, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.8175004747108, 9.3002596336365],
                      [7.2350009494215, 8.600519267273],
                      [7.7175004747108, 9.3002596336365],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.7175004747108, 9.3002596336365],
                      [8.2, 10],
                      [7.3, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.8175004747108, 9.3002596336365],
                      [7.7175004747108, 9.3002596336365],
                      [7.3, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.5015213221121, 8.5415101473798],
                      [6.3682611357668, 8.5710147073264],
                      [5.9507606610561, 9.2707550736899],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.3682611357668, 8.5710147073264],
                      [7.2350009494215, 8.600519267273],
                      [6.8175004747108, 9.3002596336365],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.8175004747108, 9.3002596336365],
                      [6.4, 10],
                      [5.9507606610561, 9.2707550736899],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.3682611357668, 8.5710147073264],
                      [6.8175004747108, 9.3002596336365],
                      [5.9507606610561, 9.2707550736899],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0, 1.8],
                      [0.69285715652299, 2.2710149612909],
                      [0, 2.7],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.69285715652299, 2.2710149612909],
                      [1.385714313046, 2.7420299225818],
                      [0.69285715652299, 3.1710149612909],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.69285715652299, 3.1710149612909],
                      [0, 3.6],
                      [0, 2.7],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.69285715652299, 2.2710149612909],
                      [0.69285715652299, 3.1710149612909],
                      [0, 2.7],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0, 3.6],
                      [0.69285715652299, 3.1710149612909],
                      [0.73144252015757, 4.0010780217736],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.69285715652299, 3.1710149612909],
                      [1.385714313046, 2.7420299225818],
                      [1.4242996766806, 3.5720929830645],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.4242996766806, 3.5720929830645],
                      [1.4628850403151, 4.4021560435473],
                      [0.73144252015757, 4.0010780217736],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.69285715652299, 3.1710149612909],
                      [1.4242996766806, 3.5720929830645],
                      [0.73144252015757, 4.0010780217736],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0, 5.4],
                      [0.72113861843797, 5.7856129991983],
                      [0, 6.3],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.72113861843797, 5.7856129991983],
                      [1.4422772368759, 6.1712259983965],
                      [0.72113861843797, 6.6856129991983],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.72113861843797, 6.6856129991983],
                      [0, 7.2],
                      [0, 6.3],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.72113861843797, 5.7856129991983],
                      [0.72113861843797, 6.6856129991983],
                      [0, 6.3],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.8946538879037, 8.2800240020632],
                      [1.3755961976764, 8.2264882735091],
                      [1.641346268623, 7.8323813623876],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.3755961976764, 8.2264882735091],
                      [0.85653850744922, 8.172952544955],
                      [1.1222885783958, 7.7788456338335],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.1222885783958, 7.7788456338335],
                      [1.3880386493424, 7.3847387227119],
                      [1.641346268623, 7.8323813623876],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.3755961976764, 8.2264882735091],
                      [1.1222885783958, 7.7788456338335],
                      [1.641346268623, 7.8323813623876],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [10, 0],
                      [9.2629967111048, 0.73700328889523],
                      [9, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.2629967111048, 0.73700328889523],
                      [8.5259934222095, 1.4740065777905],
                      [8.2629967111048, 0.73700328889523],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2629967111048, 0.73700328889523],
                      [8, 0],
                      [9, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.2629967111048, 0.73700328889523],
                      [8.2629967111048, 0.73700328889523],
                      [9, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [10, 2],
                      [9.2629967111048, 1.7370032888952],
                      [10, 1],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.2629967111048, 1.7370032888952],
                      [8.5259934222095, 1.4740065777905],
                      [9.2629967111048, 0.73700328889523],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.2629967111048, 0.73700328889523],
                      [10, 0],
                      [10, 1],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.2629967111048, 1.7370032888952],
                      [9.2629967111048, 0.73700328889523],
                      [10, 1],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [10, 10],
                      [9.3611248882827, 9.3645995577385],
                      [10, 9],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.3611248882827, 9.3645995577385],
                      [8.7222497765654, 8.729199115477],
                      [9.3611248882827, 8.3645995577385],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.3611248882827, 8.3645995577385],
                      [10, 8],
                      [10, 9],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.3611248882827, 9.3645995577385],
                      [9.3611248882827, 8.3645995577385],
                      [10, 9],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.8436071728932, 1.8856658053655],
                      [6.1382955366996, 2.4994799986163],
                      [5.8687874377164, 1.7627188134496],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.1382955366996, 2.4994799986163],
                      [5.4329839005059, 3.113294191867],
                      [5.1634758015227, 2.3765330067003],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.1634758015227, 2.3765330067003],
                      [4.8939677025396, 1.6397718215336],
                      [5.8687874377164, 1.7627188134496],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.1382955366996, 2.4994799986163],
                      [5.1634758015227, 2.3765330067003],
                      [5.8687874377164, 1.7627188134496],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2, 0],
                      [1.6295634783119, 0.63310412624353],
                      [1, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.6295634783119, 0.63310412624353],
                      [1.2591269566237, 1.2662082524871],
                      [0.62956347831186, 0.63310412624353],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.62956347831186, 0.63310412624353],
                      [0, 0],
                      [1, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.6295634783119, 0.63310412624353],
                      [0.62956347831186, 0.63310412624353],
                      [1, 0],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [10, 8],
                      [9.3611248882827, 8.3645995577385],
                      [9.0881239667028, 7.522738155056],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.3611248882827, 8.3645995577385],
                      [8.7222497765654, 8.729199115477],
                      [8.4492488549855, 7.8873377127945],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.4492488549855, 7.8873377127945],
                      [8.1762479334055, 7.0454763101119],
                      [9.0881239667028, 7.522738155056],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.3611248882827, 8.3645995577385],
                      [8.4492488549855, 7.8873377127945],
                      [9.0881239667028, 7.522738155056],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2, 10],
                      [8.4611248882827, 9.3645995577385],
                      [9.1, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.4611248882827, 9.3645995577385],
                      [8.7222497765654, 8.729199115477],
                      [9.3611248882827, 9.3645995577385],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.3611248882827, 9.3645995577385],
                      [10, 10],
                      [9.1, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.4611248882827, 9.3645995577385],
                      [9.3611248882827, 9.3645995577385],
                      [9.1, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0, 0],
                      [0.62956347831186, 0.63310412624353],
                      [0, 0.9],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.62956347831186, 0.63310412624353],
                      [1.2591269566237, 1.2662082524871],
                      [0.62956347831186, 1.5331041262435],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.62956347831186, 1.5331041262435],
                      [0, 1.8],
                      [0, 0.9],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.62956347831186, 0.63310412624353],
                      [0.62956347831186, 1.5331041262435],
                      [0, 0.9],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.9114313993612, 4.9648721667691],
                      [5.1722076499336, 4.039083179318],
                      [5.8235924632002, 4.4583466612753],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.1722076499336, 4.039083179318],
                      [5.4329839005059, 3.113294191867],
                      [6.0843687137726, 3.5325576738242],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.0843687137726, 3.5325576738242],
                      [6.7357535270392, 3.9518211557815],
                      [5.8235924632002, 4.4583466612753],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.1722076499336, 4.039083179318],
                      [6.0843687137726, 3.5325576738242],
                      [5.8235924632002, 4.4583466612753],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.9114313993612, 4.9648721667691],
                      [3.9626555899685, 5.0070071463088],
                      [4.4534911816855, 4.1424265651601],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.9626555899685, 5.0070071463088],
                      [3.0138797805757, 5.0491421258485],
                      [3.5047153722927, 4.1845615446998],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.5047153722927, 4.1845615446998],
                      [3.9955509640098, 3.3199809635511],
                      [4.4534911816855, 4.1424265651601],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.9626555899685, 5.0070071463088],
                      [3.5047153722927, 4.1845615446998],
                      [4.4534911816855, 4.1424265651601],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.1762479334055, 7.0454763101119],
                      [7.3124793506489, 7.2173644014872],
                      [7.3513644215567, 6.454395393044],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.3124793506489, 7.2173644014872],
                      [6.4487107678922, 7.3892524928625],
                      [6.4875958388, 6.6262834844193],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.4875958388, 6.6262834844193],
                      [6.5264809097078, 5.8633144759761],
                      [7.3513644215567, 6.454395393044],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.3124793506489, 7.2173644014872],
                      [6.4875958388, 6.6262834844193],
                      [7.3513644215567, 6.454395393044],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.9955509640098, 3.3199809635511],
                      [4.7142674322578, 3.2166375777091],
                      [4.4534911816855, 4.1424265651601],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.7142674322578, 3.2166375777091],
                      [5.4329839005059, 3.113294191867],
                      [5.1722076499336, 4.039083179318],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.1722076499336, 4.039083179318],
                      [4.9114313993612, 4.9648721667691],
                      [4.4534911816855, 4.1424265651601],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.7142674322578, 3.2166375777091],
                      [5.1722076499336, 4.039083179318],
                      [4.4534911816855, 4.1424265651601],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.804302724814, 6.8954422635715],
                      [5.6265067463531, 7.142347378217],
                      [5.1529120234631, 7.7184762054756],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.6265067463531, 7.142347378217],
                      [6.4487107678922, 7.3892524928625],
                      [5.9751160450022, 7.9653813201211],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.9751160450022, 7.9653813201211],
                      [5.5015213221121, 8.5415101473798],
                      [5.1529120234631, 7.7184762054756],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.6265067463531, 7.142347378217],
                      [5.9751160450022, 7.9653813201211],
                      [5.1529120234631, 7.7184762054756],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.9955509640098, 3.3199809635511],
                      [3.2745705388068, 3.3902225213138],
                      [3.4527357170412, 2.5544961517023],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.2745705388068, 3.3902225213138],
                      [2.5535901136038, 3.4604640790764],
                      [2.7317552918382, 2.624737709465],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.7317552918382, 2.624737709465],
                      [2.9099204700726, 1.7890113398535],
                      [3.4527357170412, 2.5544961517023],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.2745705388068, 3.3902225213138],
                      [2.7317552918382, 2.624737709465],
                      [3.4527357170412, 2.5544961517023],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.9099204700726, 1.7890113398535],
                      [2.7317552918382, 2.624737709465],
                      [2.1478173915593, 2.2655206312176],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.7317552918382, 2.624737709465],
                      [2.5535901136038, 3.4604640790764],
                      [1.9696522133249, 3.1012470008291],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.9696522133249, 3.1012470008291],
                      [1.385714313046, 2.7420299225818],
                      [2.1478173915593, 2.2655206312176],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.7317552918382, 2.624737709465],
                      [1.9696522133249, 3.1012470008291],
                      [2.1478173915593, 2.2655206312176],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.9099204700726, 1.7890113398535],
                      [2.0845237133481, 1.5276097961703],
                      [2.4549602350363, 0.89450566992675],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.0845237133481, 1.5276097961703],
                      [1.2591269566237, 1.2662082524871],
                      [1.6295634783119, 0.63310412624353],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.6295634783119, 0.63310412624353],
                      [2, 0],
                      [2.4549602350363, 0.89450566992675],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.0845237133481, 1.5276097961703],
                      [1.6295634783119, 0.63310412624353],
                      [2.4549602350363, 0.89450566992675],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.2350009494215, 8.600519267273],
                      [6.8418558586568, 7.9948858800677],
                      [7.7056244414135, 7.8229977886925],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.8418558586568, 7.9948858800677],
                      [6.4487107678922, 7.3892524928625],
                      [7.3124793506489, 7.2173644014872],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.3124793506489, 7.2173644014872],
                      [8.1762479334055, 7.0454763101119],
                      [7.7056244414135, 7.8229977886925],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.8418558586568, 7.9948858800677],
                      [7.3124793506489, 7.2173644014872],
                      [7.7056244414135, 7.8229977886925],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8, 0],
                      [8.2629967111048, 0.73700328889523],
                      [7.4218035864466, 0.94283290268276],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2629967111048, 0.73700328889523],
                      [8.5259934222095, 1.4740065777905],
                      [7.6848002975514, 1.679836191578],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.6848002975514, 1.679836191578],
                      [6.8436071728932, 1.8856658053655],
                      [7.4218035864466, 0.94283290268276],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.2629967111048, 0.73700328889523],
                      [7.6848002975514, 1.679836191578],
                      [7.4218035864466, 0.94283290268276],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.1762479334055, 7.0454763101119],
                      [8.4492488549855, 7.8873377127945],
                      [7.7056244414135, 7.8229977886925],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.4492488549855, 7.8873377127945],
                      [8.7222497765654, 8.729199115477],
                      [7.9786253629935, 8.664859191375],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.9786253629935, 8.664859191375],
                      [7.2350009494215, 8.600519267273],
                      [7.7056244414135, 7.8229977886925],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.4492488549855, 7.8873377127945],
                      [7.9786253629935, 8.664859191375],
                      [7.7056244414135, 7.8229977886925],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.3165516579584, 3.0207668905861],
                      [8.421272540084, 2.2473867341883],
                      [9.1582758289792, 2.510383445293],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.421272540084, 2.2473867341883],
                      [8.5259934222095, 1.4740065777905],
                      [9.2629967111048, 1.7370032888952],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [9.2629967111048, 1.7370032888952],
                      [10, 2],
                      [9.1582758289792, 2.510383445293],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.421272540084, 2.2473867341883],
                      [9.2629967111048, 1.7370032888952],
                      [9.1582758289792, 2.510383445293],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.385714313046, 2.7420299225818],
                      [1.3224206348348, 2.0041190875344],
                      [2.1478173915593, 2.2655206312176],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.3224206348348, 2.0041190875344],
                      [1.2591269566237, 1.2662082524871],
                      [2.0845237133481, 1.5276097961703],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.0845237133481, 1.5276097961703],
                      [2.9099204700726, 1.7890113398535],
                      [2.1478173915593, 2.2655206312176],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.3224206348348, 2.0041190875344],
                      [2.0845237133481, 1.5276097961703],
                      [2.1478173915593, 2.2655206312176],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.8436071728932, 1.8856658053655],
                      [7.6848002975514, 1.679836191578],
                      [7.5800794154258, 2.4532163479758],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.6848002975514, 1.679836191578],
                      [8.5259934222095, 1.4740065777905],
                      [8.421272540084, 2.2473867341883],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.421272540084, 2.2473867341883],
                      [8.3165516579584, 3.0207668905861],
                      [7.5800794154258, 2.4532163479758],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.6848002975514, 1.679836191578],
                      [8.421272540084, 2.2473867341883],
                      [7.5800794154258, 2.4532163479758],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.4422772368759, 6.1712259983965],
                      [1.4151579431092, 6.7779823605542],
                      [0.72113861843797, 6.6856129991983],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.4151579431092, 6.7779823605542],
                      [1.3880386493424, 7.3847387227119],
                      [0.6940193246712, 7.292369361356],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.6940193246712, 7.292369361356],
                      [0, 7.2],
                      [0.72113861843797, 6.6856129991983],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.4151579431092, 6.7779823605542],
                      [0.6940193246712, 7.292369361356],
                      [0.72113861843797, 6.6856129991983],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.5015213221121, 8.5415101473798],
                      [5.9751160450022, 7.9653813201211],
                      [6.3682611357668, 8.5710147073264],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.9751160450022, 7.9653813201211],
                      [6.4487107678922, 7.3892524928625],
                      [6.8418558586568, 7.9948858800677],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [6.8418558586568, 7.9948858800677],
                      [7.2350009494215, 8.600519267273],
                      [6.3682611357668, 8.5710147073264],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.9751160450022, 7.9653813201211],
                      [6.8418558586568, 7.9948858800677],
                      [6.3682611357668, 8.5710147073264],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.2350009494215, 8.600519267273],
                      [7.9786253629935, 8.664859191375],
                      [7.7175004747108, 9.3002596336365],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.9786253629935, 8.664859191375],
                      [8.7222497765654, 8.729199115477],
                      [8.4611248882827, 9.3645995577385],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [8.4611248882827, 9.3645995577385],
                      [8.2, 10],
                      [7.7175004747108, 9.3002596336365],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [7.9786253629935, 8.664859191375],
                      [8.4611248882827, 9.3645995577385],
                      [7.7175004747108, 9.3002596336365],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0, 1.8],
                      [0.62956347831186, 1.5331041262435],
                      [0.69285715652299, 2.2710149612909],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.62956347831186, 1.5331041262435],
                      [1.2591269566237, 1.2662082524871],
                      [1.3224206348348, 2.0041190875344],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.3224206348348, 2.0041190875344],
                      [1.385714313046, 2.7420299225818],
                      [0.69285715652299, 2.2710149612909],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.62956347831186, 1.5331041262435],
                      [1.3224206348348, 2.0041190875344],
                      [0.69285715652299, 2.2710149612909],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.8939677025396, 1.6397718215336],
                      [5.1634758015227, 2.3765330067003],
                      [4.4447593332747, 2.4798763925424],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.1634758015227, 2.3765330067003],
                      [5.4329839005059, 3.113294191867],
                      [4.7142674322578, 3.2166375777091],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.7142674322578, 3.2166375777091],
                      [3.9955509640098, 3.3199809635511],
                      [4.4447593332747, 2.4798763925424],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [5.1634758015227, 2.3765330067003],
                      [4.7142674322578, 3.2166375777091],
                      [4.4447593332747, 2.4798763925424],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.385714313046, 2.7420299225818],
                      [1.9696522133249, 3.1012470008291],
                      [1.4242996766806, 3.5720929830645],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.9696522133249, 3.1012470008291],
                      [2.5535901136038, 3.4604640790764],
                      [2.0082375769595, 3.9313100613119],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.0082375769595, 3.9313100613119],
                      [1.4628850403151, 4.4021560435473],
                      [1.4242996766806, 3.5720929830645],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.9696522133249, 3.1012470008291],
                      [2.0082375769595, 3.9313100613119],
                      [1.4242996766806, 3.5720929830645],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.8907433260204, 6.9124552863459],
                      [3.3979163168566, 6.4339666234898],
                      [3.8475230254172, 6.9039487749587],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.3979163168566, 6.4339666234898],
                      [3.9050893076928, 5.9554779606337],
                      [4.3546960162534, 6.4254601121026],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.3546960162534, 6.4254601121026],
                      [4.804302724814, 6.8954422635715],
                      [3.8475230254172, 6.9039487749587],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.3979163168566, 6.4339666234898],
                      [4.3546960162534, 6.4254601121026],
                      [3.8475230254172, 6.9039487749587],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0, 9],
                      [0.42826925372461, 8.5864762724775],
                      [0.5, 9],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.42826925372461, 8.5864762724775],
                      [0.85653850744922, 8.172952544955],
                      [0.92826925372461, 8.5864762724775],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.92826925372461, 8.5864762724775],
                      [1, 9],
                      [0.5, 9],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.42826925372461, 8.5864762724775],
                      [0.92826925372461, 8.5864762724775],
                      [0.5, 9],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.9114313993612, 4.9648721667691],
                      [4.408260353527, 5.4601750637014],
                      [3.9626555899685, 5.0070071463088],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.408260353527, 5.4601750637014],
                      [3.9050893076928, 5.9554779606337],
                      [3.4594845441343, 5.5023100432411],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.4594845441343, 5.5023100432411],
                      [3.0138797805757, 5.0491421258485],
                      [3.9626555899685, 5.0070071463088],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [4.408260353527, 5.4601750637014],
                      [3.4594845441343, 5.5023100432411],
                      [3.9626555899685, 5.0070071463088],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.4628850403151, 4.4021560435473],
                      [2.0082375769595, 3.9313100613119],
                      [2.2383824104454, 4.7256490846979],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.0082375769595, 3.9313100613119],
                      [2.5535901136038, 3.4604640790764],
                      [2.7837349470898, 4.2548031024625],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.7837349470898, 4.2548031024625],
                      [3.0138797805757, 5.0491421258485],
                      [2.2383824104454, 4.7256490846979],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.0082375769595, 3.9313100613119],
                      [2.7837349470898, 4.2548031024625],
                      [2.2383824104454, 4.7256490846979],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.0138797805757, 5.0491421258485],
                      [2.7837349470898, 4.2548031024625],
                      [3.5047153722927, 4.1845615446998],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.7837349470898, 4.2548031024625],
                      [2.5535901136038, 3.4604640790764],
                      [3.2745705388068, 3.3902225213138],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [3.2745705388068, 3.3902225213138],
                      [3.9955509640098, 3.3199809635511],
                      [3.5047153722927, 4.1845615446998],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [2.7837349470898, 4.2548031024625],
                      [3.2745705388068, 3.3902225213138],
                      [3.5047153722927, 4.1845615446998],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.3880386493424, 7.3847387227119],
                      [1.1222885783958, 7.7788456338335],
                      [0.6940193246712, 7.292369361356],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.1222885783958, 7.7788456338335],
                      [0.85653850744922, 8.172952544955],
                      [0.42826925372461, 7.6864762724775],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.42826925372461, 7.6864762724775],
                      [0, 7.2],
                      [0.6940193246712, 7.292369361356],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1.1222885783958, 7.7788456338335],
                      [0.42826925372461, 7.6864762724775],
                      [0.6940193246712, 7.292369361356],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0, 9],
                      [0.25, 9.25],
                      [0, 9.5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.25, 9.25],
                      [0.5, 9.5],
                      [0.25, 9.75],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.25, 9.75],
                      [0, 10],
                      [0, 9.5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.25, 9.25],
                      [0.25, 9.75],
                      [0, 9.5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0, 10],
                      [0.25, 9.75],
                      [0.5, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.25, 9.75],
                      [0.5, 9.5],
                      [0.75, 9.75],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.75, 9.75],
                      [1, 10],
                      [0.5, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.25, 9.75],
                      [0.75, 9.75],
                      [0.5, 10],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1, 9],
                      [0.75, 9.25],
                      [0.5, 9],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.75, 9.25],
                      [0.5, 9.5],
                      [0.25, 9.25],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.25, 9.25],
                      [0, 9],
                      [0.5, 9],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.75, 9.25],
                      [0.25, 9.25],
                      [0.5, 9],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [1, 10],
                      [0.75, 9.75],
                      [1, 9.5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.75, 9.75],
                      [0.5, 9.5],
                      [0.75, 9.25],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.75, 9.25],
                      [1, 9],
                      [1, 9.5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                  [
                    [
                      [0.75, 9.75],
                      [0.75, 9.25],
                      [1, 9.5],
                    ],
                    ['M', 'L', 'L', 'Z'],
                  ],
                ],
                pathtransforms: [],
                alphas: [1],
                edgecolors: [],
                facecolors: [
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                ],
                edgewidths: [0.25],
                offsetcoordinates: 'display',
                pathcoordinates: 'data',
                zorder: 1,
                id: 'el185842141570853776',
              },
              {
                offsets: 'data05',
                xindex: 0,
                yindex: 1,
                paths: [
                  [
                    [
                      [0, -0.5],
                      [0.13260155, -0.5],
                      [0.25978993539242673, -0.44731684579412084],
                      [0.3535533905932738, -0.3535533905932738],
                      [0.44731684579412084, -0.25978993539242673],
                      [0.5, -0.13260155],
                      [0.5, 0],
                      [0.5, 0.13260155],
                      [0.44731684579412084, 0.25978993539242673],
                      [0.3535533905932738, 0.3535533905932738],
                      [0.25978993539242673, 0.44731684579412084],
                      [0.13260155, 0.5],
                      [0, 0.5],
                      [-0.13260155, 0.5],
                      [-0.25978993539242673, 0.44731684579412084],
                      [-0.3535533905932738, 0.3535533905932738],
                      [-0.44731684579412084, 0.25978993539242673],
                      [-0.5, 0.13260155],
                      [-0.5, 0],
                      [-0.5, -0.13260155],
                      [-0.44731684579412084, -0.25978993539242673],
                      [-0.3535533905932738, -0.3535533905932738],
                      [-0.25978993539242673, -0.44731684579412084],
                      [-0.13260155, -0.5],
                      [0, -0.5],
                    ],
                    ['M', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'Z'],
                  ],
                ],
                pathtransforms: [[0, 0, 0, 0, 0, 0]],
                alphas: [1],
                edgecolors: [
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                ],
                facecolors: [
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#00007F',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                  '#7F0000',
                ],
                edgewidths: [1],
                offsetcoordinates: 'data',
                pathcoordinates: 'display',
                zorder: 1,
                id: 'el185842141570749008',
              },
            ],
            images: [],
            sharex: [],
            sharey: [],
          },
        ],
        data: {
          data01: [
            [0, 0, 10, 10, 1, 10, 9],
            [10, 0, 10, 1, 0, 9, 0],
          ],
          data02: [
            [0, 9],
            [1, 9],
          ],
          data03: [
            [1, 9],
            [1, 10],
          ],
          data04: [[0, 0]],
          data05: [
            [1.9456220090166, 6.1077502342967325],
            [2.7314232808664998, 5.546708298022733],
            [2.669855053588833, 6.4783648782714325],
            [2.4489667811573, 6.044274470196967],
            [0.14275641790820331, 7.6621587574925],
            [0.5710256716328133, 8.148635029969999],
            [0.14275641790820331, 8.562158757492499],
            [0.28551283581640663, 8.124317514984998],
            [3.141891959336, 5.510750291728933],
            [3.5874967228945662, 5.963918209121532],
            [3.080323732058333, 6.442406871977633],
            [3.2699041380962997, 5.972358457609367],
            [3.0076535279209997, 8.646924813726265],
            [2.2506831093615327, 9.000245819181465],
            [2.1884596601987334, 8.497448527819467],
            [2.482265432493767, 8.714873053575733],
            [1.469850131038233, 9.880936430797867],
            [1.9794005241529, 9.523745723191468],
            [2.369850131038233, 9.880936430797867],
            [1.9397002620764667, 9.761872861595734],
            [1.1251987325588033, 8.742162757836365],
            [1.05346798628341, 8.328639030313866],
            [1.5725256765106332, 8.382174758867965],
            [1.250397465117603, 8.484325515672733],
            [5.9702762938344, 5.885595389040834],
            [5.1091872013875, 6.4016592828385335],
            [5.1627515386611, 5.4363742344373325],
            [5.414071677960999, 5.907876302105567],
            [1.717949529785333, 4.8048320497390336],
            [2.493446899915633, 5.128325090889633],
            [1.7076456280657333, 5.689367027163634],
            [1.9730140192555665, 5.2075080559307665],
            [7.017195489834734, 3.452286219846267],
            [7.071122312761732, 2.4192085446382667],
            [7.807594555294333, 2.9867590872485668],
            [7.298637452630267, 2.9527512839110335],
            [2.7920237349296, 9.644099193110666],
            [2.4015741280442664, 9.286908485504267],
            [3.1585445466037, 8.933587480049066],
            [2.7840474698592, 9.288198386221334],
            [1.9762329211629002, 7.902881669551768],
            [1.7229253018822668, 7.455239029876132],
            [2.4742776402212665, 7.219097311693099],
            [2.0578119544221334, 7.525739337040332],
            [3.222173603891367, 9.763162762312799],
            [3.5886944155654668, 9.0526510492512],
            [4.122173603891366, 9.763162762312799],
            [3.644347207782733, 9.5263255246256],
            [6.2264895219229, 6.2896587767230665],
            [6.187604451015099, 7.052627785166266],
            [5.365400429475999, 6.805722670520766],
            [5.926498134138001, 6.716003077470033],
            [4.572427157576733, 9.520081120209433],
            [4.038947969250833, 8.809569407147833],
            [5.0231878186328, 8.790836193899333],
            [4.544854315153466, 9.040162240418866],
            [6.536600863592666, 3.4677074367264],
            [5.885216050326034, 3.0484439547691666],
            [6.590527686519666, 2.4346297615184334],
            [6.337448200146133, 2.9835937176713334],
            [3.1529272845528333, 8.251397597319366],
            [2.3337334168305666, 8.101921311412568],
            [2.8317781358889, 7.4181369535538995],
            [2.7728129457574333, 7.923818620761933],
            [4.072998423386499, 8.292143117743098],
            [4.708628974119399, 7.450375962590432],
            [5.057238272768466, 8.273409904494565],
            [4.6129552234248, 8.005309661609367],
            [3.6378687573712, 8.020633974237434],
            [3.3167196087072996, 7.187373330471965],
            [4.2734993081041, 7.1788668190848],
            [3.7426958913941997, 7.462291374598067],
            [9.696041322234267, 7.5075793850186665],
            [8.784165288937034, 7.030317540074634],
            [9.696041322234267, 6.5075793850186665],
            [9.392082644468534, 7.015158770037333],
            [1.7662860563073333, 8.56761909884],
            [1.8285095054701666, 9.070416390202],
            [1.3189591123554998, 8.9276070978084],
            [1.637918224711, 8.8552141956168],
            [7.727051194870766, 4.957661689601399],
            [6.852782558164966, 5.3979646143580995],
            [6.9574188668306665, 4.4422179542608],
            [7.179084206622133, 4.932614752740099],
            [6.292184760871933, 5.394991871075833],
            [5.484660005698633, 4.945770716472333],
            [6.3968210695376335, 4.439245210978534],
            [6.057888612036066, 4.926669266175566],
            [4.414298546705166, 1.3913497709980032],
            [3.422274930471666, 1.46596953015795],
            [3.967314695435366, 0.5714638602311866],
            [3.9346293908707333, 1.1429277204623867],
            [8.0253963195792, 4.483903758703066],
            [7.2557639915391, 3.9684600233624665],
            [8.046163056998699, 3.5029328907647668],
            [7.775774456039, 3.9850988909434335],
            [9.7125030305199, 5.497118104410433],
            [8.8500121220796, 4.988472417641766],
            [9.7125030305199, 4.497118104410433],
            [9.425006061039799, 4.994236208820866],
            [5.482327950423266, 0.27329530358893667],
            [4.929311801693066, 1.09318121435574],
            [4.482327950423266, 0.27329530358893667],
            [4.964655900846533, 0.5465906071778733],
            [8.569437398405999, 4.4919335660728],
            [8.590204135825498, 3.5109626981344997],
            [9.431928306846299, 4.000579252841433],
            [8.8638566136926, 4.001158505682899],
            [3.4849867450120997, 0.29816855664224995],
            [2.9399469800483997, 1.1926742265689998],
            [2.4849867450120997, 0.29816855664224995],
            [2.9699734900241994, 0.5963371132844999],
            [9.408544352754166, 6.0046974894291],
            [8.4966683194569, 6.527435644485067],
            [8.546053444313833, 5.496051802660434],
            [8.817088705508299, 6.0093949788582],
            [7.9671335959317995, 5.4732708819898],
            [7.917748471074867, 6.504654723814399],
            [7.092864959226032, 5.913573806746499],
            [7.659249008744233, 5.9638331375169],
            [9.7194252763264, 3.5034611484309996],
            [8.877701105305599, 3.013844593724033],
            [9.7194252763264, 2.5034611484309996],
            [9.438850552652799, 3.0069222968619997],
            [2.398881531716633, 6.867630977748666],
            [1.6475291933776666, 7.103772695931667],
            [1.6746484871444, 6.497016333773965],
            [1.9070197374128999, 6.822806669151433],
            [5.0502535536853665, 9.756918357896632],
            [5.501014214741433, 9.027673431586532],
            [5.950253553685366, 9.756918357896632],
            [5.5005071073707335, 9.513836715793266],
            [0.24381417338585668, 4.033692673924533],
            [0.9752566935434134, 4.434770695698166],
            [0.24381417338585668, 4.933692673924533],
            [0.48762834677171335, 4.467385347849067],
            [7.473934528815533, 0.31427763422758664],
            [6.895738115262134, 1.2571105369103397],
            [6.473934528815533, 0.31427763422758664],
            [6.947869057631067, 0.6285552684551733],
            [4.672288601051666, 6.417019863614799],
            [4.2226818924910665, 5.9470377121458995],
            [4.725852938325266, 5.4517348152136],
            [4.5402744772893335, 5.9385974636581],
            [4.4135570407068, 1.9446799315898664],
            [3.9643486714418996, 2.7847845025985998],
            [3.4215334244733, 2.0192996907497998],
            [3.933146378874, 2.2495880416461],
            [1.2156362330227233, 4.8633083620976],
            [1.2053323313031232, 5.747843339522234],
            [0.48419371286518, 5.362230340323966],
            [0.9683874257303465, 5.324460680647933],
            [1.1698501310382332, 9.214269764131199],
            [1.6794005241529, 9.3570790565248],
            [1.1698501310382332, 9.714269764131199],
            [1.3397002620764664, 9.4285395282624],
            [5.9562624792387995, 0.5875729378165233],
            [6.3780660656854, 1.5304058404992864],
            [5.403246330508599, 1.4074588485833364],
            [5.912524958477599, 1.1751458756330566],
            [6.8391668249036, 9.766753211212166],
            [7.2566672996143655, 9.067012844848666],
            [7.7391668249036, 9.766753211212166],
            [7.2783336498072, 9.533506422424333],
            [5.940181039644999, 8.7944266427987],
            [6.806920853299699, 8.8239312027453],
            [6.389420378588967, 9.5236715691088],
            [6.378840757177899, 9.047343138217599],
            [0.2309523855076633, 2.2570049870969666],
            [0.92380954203066, 2.7280199483878667],
            [0.2309523855076633, 3.157004987096967],
            [0.4619047710153266, 2.7140099741939334],
            [0.47476655889352, 3.5906976610215],
            [1.1676237154165299, 3.1617126223124004],
            [1.2062090790510898, 3.991775682795133],
            [0.9495331177870533, 3.581395322043],
            [0.24037953947932333, 5.828537666399432],
            [0.96151815791728, 6.2141506655977],
            [0.24037953947932333, 6.728537666399434],
            [0.48075907895864667, 6.257075332798866],
            [1.6371987847343665, 8.112964545986632],
            [1.11814109450714, 8.059428817432533],
            [1.3838911654537331, 7.665321906311],
            [1.3797436815650665, 7.945905089910067],
            [9.420998903701598, 0.24566776296507667],
            [8.683995614806365, 0.98267105186032],
            [8.420998903701598, 0.24566776296507667],
            [8.8419978074032, 0.49133552593015334],
            [9.754332237034932, 1.5790010962983998],
            [9.0173289481397, 1.3160043851936432],
            [9.754332237034932, 0.57900109629841],
            [9.508664474069866, 1.1580021925968098],
            [9.787041629427566, 9.454866519246167],
            [9.148166517710266, 8.819466076984668],
            [9.787041629427566, 8.454866519246167],
            [9.574083258855133, 8.909733038492334],
            [6.283563382436399, 2.0492882058104662],
            [5.578251746242733, 2.6631023990612],
            [5.308743647259566, 1.9263412138944997],
            [5.723519591979565, 2.2129106062553996],
            [1.5431878261039664, 0.2110347087478433],
            [1.17275130441582, 0.8441388349913865],
            [0.5431878261039533, 0.2110347087478433],
            [1.08637565220792, 0.4220694174956866],
            [9.483082951661832, 7.9624459042648335],
            [8.844207839944533, 8.327045462003333],
            [8.571206918364599, 7.485184059320799],
            [8.966165903323667, 7.924891808529667],
            [8.587041629427567, 9.788199852579499],
            [8.848166517710267, 9.152799410318],
            [9.487041629427566, 9.788199852579499],
            [8.974083258855133, 9.576399705159],
            [0.20985449277062, 0.5110347087478433],
            [0.8394179710824733, 1.1441388349913766],
            [0.20985449277062, 1.4110347087478332],
            [0.41970898554124, 1.0220694174956764],
            [5.302410504165, 4.487434002454133],
            [5.563186754737366, 3.5616450150030667],
            [6.214571568003999, 3.9809084969603328],
            [5.693389608968799, 4.009995838139167],
            [4.442526057005066, 4.704768626079333],
            [3.4937502476122995, 4.746903605619034],
            [3.984585839329333, 3.8823230244703333],
            [3.9736207146488995, 4.444665085389567],
            [7.6133639018703665, 6.905745368214366],
            [6.749595319113699, 7.077633459589666],
            [6.788480390021499, 6.3146644511464665],
            [7.0504798703352, 6.766014426316833],
            [4.3877698593177, 3.5596817021401],
            [5.106486327565766, 3.4563383162980337],
            [4.8457100769934325, 4.3821273037490664],
            [4.7799887546256326, 3.799382440729067],
            [5.1945738315434, 7.252088615754699],
            [6.016777853082499, 7.4989937304001995],
            [5.543183130192466, 8.075122557658833],
            [5.584844938272799, 7.6087349679379],
            [3.5742857399526002, 3.088233212189067],
            [2.8533053147496, 3.158474769951733],
            [3.031470492984, 2.3227484003402665],
            [3.1530205158954, 2.856485460827033],
            [2.596497717823367, 2.2264232268453665],
            [2.4183325395889663, 3.062149596456834],
            [1.8343946393100663, 2.7029325182095],
            [2.2830749655741336, 2.6638351138372336],
            [2.483134806152333, 1.40370893531685],
            [1.6577380494279002, 1.142307391633643],
            [2.0281745711160664, 0.5092032653900933],
            [2.0563491422321, 1.0184065307801933],
            [7.260827083163933, 8.1394676453444],
            [6.8676819923993, 7.533834258139132],
            [7.731450575155966, 7.361946166763866],
            [7.286653216906399, 7.678416023415799],
            [7.894933432517132, 0.5599453971926633],
            [8.157930143621899, 1.29694868608791],
            [7.316737018963733, 1.50277829987542],
            [7.789866865034266, 1.11989079438533],
            [8.110373743268166, 7.5852706038663],
            [8.383374664848134, 8.427132006548833],
            [7.639750251276166, 8.362792082446834],
            [8.044499553130834, 8.125064897620666],
            [8.632033342340533, 2.5928456900224663],
            [8.736754224466099, 1.8194655336246666],
            [9.473757513361331, 2.0824622447294],
            [8.947515026722666, 2.164924489458833],
            [1.6186507798133665, 2.337223213777933],
            [1.5553571016021999, 1.5993123787306],
            [2.380753858326667, 1.8607139224137996],
            [1.8515872465807333, 1.9324165049740998],
            [7.369495628623466, 2.006239448306433],
            [8.210688753281634, 1.8004098345189334],
            [8.105967871156066, 2.573789990916733],
            [7.895384084353734, 2.1268130912473664],
            [1.19285793280769, 6.544940452716332],
            [1.1657386390409332, 7.151696814874033],
            [0.47171931436972336, 7.059327453518101],
            [0.9434386287394566, 6.918654907036165],
            [5.948299500960367, 8.359302058275766],
            [6.4218942238503995, 7.783173231017099],
            [6.815039314615033, 8.388806618222365],
            [6.395077679808599, 8.177093969171732],
            [7.643708929041932, 8.855212697428167],
            [8.387333342613866, 8.919552621530165],
            [8.126208454331167, 9.554953063791666],
            [8.052416908662334, 9.109906127583333],
            [0.4408068782782833, 1.8680396958448],
            [1.07037035659012, 1.6011438220883332],
            [1.1336640348012632, 2.3390546571357],
            [0.8816137565565498, 1.9360793916895997],
            [4.834067612445666, 2.1653937402587666],
            [5.1035757114288, 2.9021549254254664],
            [4.384859243180767, 3.005498311267533],
            [4.774167522351733, 2.691015658983933],
            [1.5932220676838331, 3.1384566354917998],
            [2.177159967962733, 3.497673713739133],
            [1.6318074313184, 3.9685196959745666],
            [1.8007298223216666, 3.5348833484018334],
            [3.3787275560980667, 6.750123561598134],
            [3.8859005469342662, 6.271634898742033],
            [4.335507255494866, 6.741617050210934],
            [3.8667117861757334, 6.587791836850366],
            [0.30942308457487, 8.862158757492498],
            [0.73769233829948, 8.44863502997],
            [0.80942308457487, 8.862158757492498],
            [0.61884616914974, 8.724317514985],
            [4.427449114285567, 5.1440181255931],
            [3.9242780684513665, 5.639321022525399],
            [3.4786733048928333, 5.1861531051328],
            [3.9434668292099335, 5.323164084417099],
            [1.9031683425733332, 4.353038396519033],
            [2.4485208792177, 3.8821924142835997],
            [2.6786657127036335, 4.676531437669633],
            [2.3434516448315663, 4.303920749490766],
            [3.100776699986066, 4.496168924336933],
            [2.8706318665001334, 3.7018299009509],
            [3.5916122917031004, 3.631588343188233],
            [3.1876736193964335, 3.943195722825367],
            [1.0681155174698, 7.4853179059671335],
            [0.8023654465232101, 7.879424817088666],
            [0.37409619279860334, 7.3929485446111665],
            [0.7481923855972032, 7.585897089222333],
            [0.08333333333333333, 9.25],
            [0.3333333333333333, 9.5],
            [0.08333333333333333, 9.75],
            [0.16666666666666666, 9.5],
            [0.25, 9.916666666666666],
            [0.5, 9.666666666666666],
            [0.75, 9.916666666666666],
            [0.5, 9.833333333333332],
            [0.75, 9.083333333333332],
            [0.5, 9.333333333333332],
            [0.25, 9.083333333333332],
            [0.5, 9.166666666666666],
            [0.9166666666666666, 9.75],
            [0.6666666666666666, 9.5],
            [0.9166666666666666, 9.25],
            [0.8333333333333333, 9.5],
          ],
          data06: [
            [1.0538203463203464, 0.9495701058201058, 0.8929022366522368],
            [1.091398508898509, 0.9495701058201058, 0.8929022366522368],
            [1.1289766714766716, 0.9495701058201058, 0.8929022366522368],
          ],
          data07: [
            [1.046304713804714, 0.8566017316017316],
            [1.333965548340548, 0.8566017316017316],
            [1.3414811808561808, 0.8566017316017316],
            [1.3414811808561808, 0.8641173641173642],
            [1.3414811808561808, 0.9736952861952862],
            [1.3414811808561808, 0.9812109187109187],
            [1.333965548340548, 0.9812109187109187],
            [1.046304713804714, 0.9812109187109187],
            [1.0387890812890814, 0.9812109187109187],
            [1.0387890812890814, 0.9736952861952862],
            [1.0387890812890814, 0.8641173641173642],
            [1.0387890812890814, 0.8566017316017316],
            [1.046304713804714, 0.8566017316017316],
          ],
        },
        id: 'el185842141571323344',
        plugins: [
          {
            type: 'reset',
          },
          {
            type: 'zoom',
            button: true,
            enabled: false,
          },
          {
            type: 'boxzoom',
            button: true,
            enabled: false,
          },
        ],
      },
      calculatedSchemeData: null,
      linesData: [
        [
          'Line1',
          [
            [7, 0, 11, 7],
            [8, 7, 12, 8],
            [9, 8, 13, 9],
            [10, 9, 14, 10],
            [11, 10, 15, 1],
          ],
        ],
        [
          'Line2',
          [
            [12, 1, 20, 16],
            [13, 16, 21, 17],
            [14, 17, 22, 18],
            [15, 18, 23, 19],
            [16, 19, 24, 2],
          ],
        ],
        [
          'Line3',
          [
            [17, 2, 29, 25],
            [18, 25, 30, 26],
            [19, 26, 31, 27],
            [20, 27, 32, 28],
            [21, 28, 33, 6],
          ],
        ],
        ['Line4', [[22, 6, 34, 3]]],
        ['Line5', [[23, 3, 35, 4]]],
        [
          'Line6',
          [
            [24, 4, 40, 36],
            [25, 36, 41, 37],
            [26, 37, 42, 38],
            [27, 38, 43, 39],
            [28, 39, 44, 0],
          ],
        ],
        ['Line7', [[29, 4, 45, 5]]],
        ['Line8', [[30, 5, 46, 6]]],
      ],
      polygonsData: [
        [
          'Surface1',
          [
            [31, 64, 66, 59, 77, 78, 79],
            [32, 36, 69, 4, 80, 81, 40],
            [33, 66, 76, 59, 82, 83, 78],
            [34, 57, 70, 60, 84, 85, 86],
            [35, 6, 70, 28, 87, 88, 33],
            [36, 5, 69, 60, 89, 90, 91],
            [37, 53, 58, 54, 92, 93, 94],
            [38, 50, 66, 64, 95, 77, 96],
            [39, 52, 62, 51, 97, 98, 99],
            [40, 28, 70, 57, 88, 84, 100],
            [41, 60, 68, 59, 101, 102, 103],
            [42, 28, 57, 27, 100, 104, 32],
            [43, 53, 73, 58, 105, 106, 92],
            [44, 27, 57, 48, 104, 107, 108],
            [45, 52, 74, 62, 109, 110, 97],
            [46, 57, 60, 59, 86, 103, 111],
            [47, 57, 58, 48, 112, 113, 107],
            [48, 57, 59, 58, 111, 114, 112],
            [49, 19, 55, 18, 115, 116, 23],
            [50, 60, 70, 5, 85, 117, 91],
            [51, 49, 53, 52, 118, 119, 120],
            [52, 53, 54, 52, 94, 121, 119],
            [53, 47, 56, 8, 122, 123, 124],
            [54, 49, 52, 51, 120, 99, 125],
            [55, 18, 49, 17, 126, 127, 22],
            [56, 9, 47, 8, 128, 124, 13],
            [57, 49, 51, 17, 125, 129, 127],
            [58, 8, 56, 7, 123, 130, 12],
            [59, 18, 55, 49, 116, 131, 126],
            [60, 49, 55, 53, 131, 132, 118],
            [61, 17, 51, 16, 129, 133, 21],
            [62, 59, 68, 64, 102, 134, 79],
            [63, 27, 48, 26, 108, 135, 31],
            [64, 38, 50, 37, 136, 137, 42],
            [65, 10, 62, 9, 138, 139, 14],
            [66, 58, 76, 54, 140, 141, 93],
            [67, 47, 61, 56, 142, 143, 122],
            [68, 50, 64, 37, 96, 144, 137],
            [69, 5, 70, 6, 117, 87, 46],
            [70, 9, 62, 47, 139, 145, 128],
            [71, 26, 63, 25, 146, 147, 30],
            [72, 48, 63, 26, 148, 146, 135],
            [73, 39, 65, 38, 149, 150, 43],
            [74, 38, 65, 50, 150, 151, 136],
            [75, 37, 64, 36, 144, 152, 41],
            [76, 60, 69, 68, 90, 153, 101],
            [77, 1, 67, 10, 154, 155, 15],
            [78, 16, 67, 1, 156, 154, 20],
            [79, 2, 71, 19, 157, 158, 24],
            [80, 62, 74, 47, 110, 159, 145],
            [81, 7, 72, 0, 160, 161, 11],
            [82, 19, 71, 55, 158, 162, 115],
            [83, 25, 71, 2, 163, 157, 29],
            [84, 0, 72, 39, 161, 164, 44],
            [85, 54, 74, 52, 165, 109, 121],
            [86, 54, 66, 61, 166, 167, 168],
            [87, 55, 73, 53, 169, 105, 132],
            [88, 61, 74, 54, 170, 165, 168],
            [89, 58, 73, 48, 106, 171, 113],
            [90, 61, 75, 56, 172, 173, 143],
            [91, 56, 75, 65, 173, 174, 175],
            [92, 56, 72, 7, 176, 160, 130],
            [93, 63, 73, 55, 177, 169, 178],
            [94, 10, 67, 62, 155, 179, 138],
            [95, 55, 71, 63, 162, 180, 178],
            [96, 51, 67, 16, 181, 156, 133],
            [97, 65, 72, 56, 182, 176, 175],
            [98, 62, 67, 51, 179, 181, 98],
            [99, 64, 68, 36, 134, 183, 152],
            [100, 48, 73, 63, 171, 177, 148],
            [101, 63, 71, 25, 180, 163, 147],
            [102, 39, 72, 65, 164, 182, 149],
            [103, 47, 74, 61, 159, 170, 142],
            [104, 65, 75, 50, 174, 184, 151],
            [105, 59, 76, 58, 83, 140, 114],
            [106, 4, 69, 5, 81, 89, 45],
            [107, 54, 76, 66, 141, 82, 166],
            [108, 50, 75, 66, 184, 185, 95],
            [109, 66, 75, 61, 185, 172, 167],
            [110, 68, 69, 36, 153, 80, 183],
          ],
        ],
        [
          'Surface2',
          [
            [111, 4, 186, 3, 187, 188, 35],
            [112, 3, 186, 6, 188, 189, 34],
            [113, 5, 186, 4, 190, 187, 45],
            [114, 6, 186, 5, 189, 190, 46],
          ],
        ],
      ],
      coordsData: [
        ['0', [0, 0, 0]],
        ['1', [1, 10, 0]],
        ['2', [2, 10, 10]],
        ['3', [3, 0, 10]],
        ['4', [4, 0, 9]],
        ['5', [5, 1, 9]],
        ['6', [6, 1, 10]],
        ['7', [7, 2, 0]],
        ['8', [8, 4, 0]],
        ['9', [9, 6, 0]],
        ['10', [10, 8, 0]],
        ['11', [11, 1, 0]],
        ['12', [12, 3, 0]],
        ['13', [13, 5, 0]],
        ['14', [14, 7, 0]],
        ['15', [15, 9, 0]],
        ['16', [16, 10, 2]],
        ['17', [17, 10, 4]],
        ['18', [18, 10, 6]],
        ['19', [19, 10, 8]],
        ['20', [20, 10, 1]],
        ['21', [21, 10, 3]],
        ['22', [22, 10, 5]],
        ['23', [23, 10, 7]],
        ['24', [24, 10, 9]],
        ['25', [25, 8.2, 10]],
        ['26', [26, 6.4, 10]],
        ['27', [27, 4.6, 10]],
        ['28', [28, 2.8, 10]],
        ['29', [29, 9.1, 10]],
        ['30', [30, 7.3, 10]],
        ['31', [31, 5.5, 10]],
        ['32', [32, 3.7, 10]],
        ['33', [33, 1.9, 10]],
        ['34', [34, 0.5, 10]],
        ['35', [35, 0, 9.5]],
        ['36', [36, 0, 7.2]],
        ['37', [37, 0, 5.4]],
        ['38', [38, 0, 3.6]],
        ['39', [39, 0, 1.8]],
        ['40', [40, 0, 8.1]],
        ['41', [41, 0, 6.3]],
        ['42', [42, 0, 4.5]],
        ['43', [43, 0, 2.7]],
        ['44', [44, 0, 0.9]],
        ['45', [45, 0.5, 9]],
        ['46', [46, 1, 9.5]],
        ['47', [47, 4.8939677025396, 1.6397718215336]],
        ['48', [48, 5.5015213221121, 8.5415101473798]],
        ['49', [49, 8.2750181831194, 4.9827086264627]],
        ['50', [50, 1.4628850403151, 4.4021560435473]],
        ['51', [51, 8.3165516579584, 3.0207668905861]],
        ['52', [52, 6.7357535270392, 3.9518211557815]],
        ['53', [53, 6.5264809097078, 5.8633144759761]],
        ['54', [54, 4.9114313993612, 4.9648721667691]],
        ['55', [55, 8.1762479334055, 7.0454763101119]],
        ['56', [56, 2.9099204700726, 1.7890113398535]],
        ['57', [57, 3.5330416233482, 8.5789765738768]],
        ['58', [58, 4.804302724814, 6.8954422635715]],
        ['59', [59, 2.8907433260204, 6.9124552863459]],
        ['60', [60, 1.8946538879037, 8.2800240020632]],
        ['61', [61, 3.9955509640098, 3.3199809635511]],
        ['62', [62, 6.8436071728932, 1.8856658053655]],
        ['63', [63, 7.2350009494215, 8.600519267273]],
        ['64', [64, 1.4422772368759, 6.1712259983965]],
        ['65', [65, 1.385714313046, 2.7420299225818]],
        ['66', [66, 3.0138797805757, 5.0491421258485]],
        ['67', [67, 8.5259934222095, 1.4740065777905]],
        ['68', [68, 1.3880386493424, 7.3847387227119]],
        ['69', [69, 0.85653850744922, 8.172952544955]],
        ['70', [70, 2.0191007862293, 9.2856185847872]],
        ['71', [71, 8.7222497765654, 8.729199115477]],
        ['72', [72, 1.2591269566237, 1.2662082524871]],
        ['73', [73, 6.4487107678922, 7.3892524928625]],
        ['74', [74, 5.4329839005059, 3.113294191867]],
        ['75', [75, 2.5535901136038, 3.4604640790764]],
        ['76', [76, 3.9050893076928, 5.9554779606337]],
        ['77', [77, 2.2280785087258, 5.6101840621225]],
        ['78', [78, 2.952311553298, 5.9807987060972]],
        ['79', [79, 2.1665102814481, 6.5418406423712]],
        ['80', [80, 0.42826925372461, 7.6864762724775]],
        ['81', [81, 0.42826925372461, 8.5864762724775]],
        ['82', [82, 3.4594845441343, 5.5023100432411]],
        ['83', [83, 3.3979163168566, 6.4339666234898]],
        ['84', [84, 2.7760712047888, 8.932297579332]],
        ['85', [85, 1.9568773370665, 8.7828212934252]],
        ['86', [86, 2.713847755626, 8.42950028797]],
        ['87', [87, 1.5095503931147, 9.6428092923936]],
        ['88', [88, 2.4095503931147, 9.6428092923936]],
        ['89', [89, 0.92826925372461, 8.5864762724775]],
        ['90', [90, 1.3755961976764, 8.2264882735091]],
        ['91', [91, 1.4473269439518, 8.6400120010316]],
        ['92', [92, 5.6653918172609, 6.3793783697738]],
        ['93', [93, 4.8578670620876, 5.9301572151703]],
        ['94', [94, 5.7189561545345, 5.4140933213726]],
        ['95', [95, 2.2383824104454, 4.7256490846979]],
        ['96', [96, 1.4525811385955, 5.2866910209719]],
        ['97', [97, 6.7896803499662, 2.9187434805735]],
        ['98', [98, 7.5800794154258, 2.4532163479758]],
        ['99', [99, 7.5261525924988, 3.4862940231838]],
        ['100', [100, 3.1665208116741, 9.2894882869384]],
        ['101', [101, 1.641346268623, 7.8323813623876]],
        ['102', [102, 2.1393909876814, 7.1485970045289]],
        ['103', [103, 2.392698606962, 7.5962396442045]],
        ['104', [104, 4.0665208116741, 9.2894882869384]],
        ['105', [105, 6.4875958388, 6.6262834844193]],
        ['106', [106, 5.6265067463531, 7.142347378217]],
        ['107', [107, 4.5172814727302, 8.5602433606283]],
        ['108', [108, 5.0507606610561, 9.2707550736899]],
        ['109', [109, 6.0843687137726, 3.5325576738242]],
        ['110', [110, 6.1382955366996, 2.4994799986163]],
        ['111', [111, 3.2118924746843, 7.7457159301113]],
        ['112', [112, 4.1686721740811, 7.7372094187242]],
        ['113', [113, 5.1529120234631, 7.7184762054756]],
        ['114', [114, 3.8475230254172, 6.9039487749587]],
        ['115', [115, 9.0881239667028, 7.522738155056]],
        ['116', [116, 9.0881239667028, 6.522738155056]],
        ['117', [117, 1.5095503931147, 9.1428092923936]],
        ['118', [118, 7.4007495464136, 5.4230115512194]],
        ['119', [119, 6.6311172183735, 4.9075678158788]],
        ['120', [120, 7.5053858550793, 4.4672648911221]],
        ['121', [121, 5.8235924632002, 4.4583466612753]],
        ['122', [122, 3.9019440863061, 1.7143915806936]],
        ['123', [123, 3.4549602350363, 0.89450566992675]],
        ['124', [124, 4.4469838512698, 0.81988591076681]],
        ['125', [125, 8.2957849205389, 4.0017377585244]],
        ['126', [126, 9.1375090915597, 5.4913543132313]],
        ['127', [127, 9.1375090915597, 4.4913543132313]],
        ['128', [128, 5.4469838512698, 0.81988591076681]],
        ['129', [129, 9.1582758289792, 3.510383445293]],
        ['130', [130, 2.4549602350363, 0.89450566992675]],
        ['131', [131, 8.2256330582624, 6.0140924682873]],
        ['132', [132, 7.3513644215567, 6.454395393044]],
        ['133', [133, 9.1582758289792, 2.510383445293]],
        ['134', [134, 1.4151579431092, 6.7779823605542]],
        ['135', [135, 5.9507606610561, 9.2707550736899]],
        ['136', [136, 0.73144252015757, 4.0010780217736]],
        ['137', [137, 0.73144252015757, 4.9010780217736]],
        ['138', [138, 7.4218035864466, 0.94283290268276]],
        ['139', [139, 6.4218035864466, 0.94283290268276]],
        ['140', [140, 4.3546960162534, 6.4254601121026]],
        ['141', [141, 4.408260353527, 5.4601750637014]],
        ['142', [142, 4.4447593332747, 2.4798763925424]],
        ['143', [143, 3.4527357170412, 2.5544961517023]],
        ['144', [144, 0.72113861843797, 5.7856129991983]],
        ['145', [145, 5.8687874377164, 1.7627188134496]],
        ['146', [146, 6.8175004747108, 9.3002596336365]],
        ['147', [147, 7.7175004747108, 9.3002596336365]],
        ['148', [148, 6.3682611357668, 8.5710147073264]],
        ['149', [149, 0.69285715652299, 2.2710149612909]],
        ['150', [150, 0.69285715652299, 3.1710149612909]],
        ['151', [151, 1.4242996766806, 3.5720929830645]],
        ['152', [152, 0.72113861843797, 6.6856129991983]],
        ['153', [153, 1.1222885783958, 7.7788456338335]],
        ['154', [154, 9.2629967111048, 0.73700328889523]],
        ['155', [155, 8.2629967111048, 0.73700328889523]],
        ['156', [156, 9.2629967111048, 1.7370032888952]],
        ['157', [157, 9.3611248882827, 9.3645995577385]],
        ['158', [158, 9.3611248882827, 8.3645995577385]],
        ['159', [159, 5.1634758015227, 2.3765330067003]],
        ['160', [160, 1.6295634783119, 0.63310412624353]],
        ['161', [161, 0.62956347831186, 0.63310412624353]],
        ['162', [162, 8.4492488549855, 7.8873377127945]],
        ['163', [163, 8.4611248882827, 9.3645995577385]],
        ['164', [164, 0.62956347831186, 1.5331041262435]],
        ['165', [165, 5.1722076499336, 4.039083179318]],
        ['166', [166, 3.9626555899685, 5.0070071463088]],
        ['167', [167, 3.5047153722927, 4.1845615446998]],
        ['168', [168, 4.4534911816855, 4.1424265651601]],
        ['169', [169, 7.3124793506489, 7.2173644014872]],
        ['170', [170, 4.7142674322578, 3.2166375777091]],
        ['171', [171, 5.9751160450022, 7.9653813201211]],
        ['172', [172, 3.2745705388068, 3.3902225213138]],
        ['173', [173, 2.7317552918382, 2.624737709465]],
        ['174', [174, 1.9696522133249, 3.1012470008291]],
        ['175', [175, 2.1478173915593, 2.2655206312176]],
        ['176', [176, 2.0845237133481, 1.5276097961703]],
        ['177', [177, 6.8418558586568, 7.9948858800677]],
        ['178', [178, 7.7056244414135, 7.8229977886925]],
        ['179', [179, 7.6848002975514, 1.679836191578]],
        ['180', [180, 7.9786253629935, 8.664859191375]],
        ['181', [181, 8.421272540084, 2.2473867341883]],
        ['182', [182, 1.3224206348348, 2.0041190875344]],
        ['183', [183, 0.6940193246712, 7.292369361356]],
        ['184', [184, 2.0082375769595, 3.9313100613119]],
        ['185', [185, 2.7837349470898, 4.2548031024625]],
        ['186', [186, 0.5, 9.5]],
        ['187', [187, 0.25, 9.25]],
        ['188', [188, 0.25, 9.75]],
        ['189', [189, 0.75, 9.75]],
        ['190', [190, 0.75, 9.25]],
      ],
      isUpdated: false,
      isLoading: false,

      stageData: {
        Initial_phase: {
          soils: [
            {
              name: 'Surface1',
              material: {
                грунт1: {
                  weight: 20,
                  poisson: 0.3,
                  mechParameter: 'linear-elastic',
                  elasticModulus: 20000,
                },
              },
              phaseActivity: true,
              comment: '',
            },
            {
              name: 'Surface2',
              material: {
                фундамент: {
                  weight: 250,
                  poisson: 0.15,
                  mechParameter: 'linear-elastic',
                  elasticModulus: 10000000,
                },
              },
              phaseActivity: true,
              comment: '',
            },
          ],
          lines: [
            {
              name: 'Line1',
              phaseActivity: true,
              propertyParams: {
                ux: 0,
                uy: 0,
              },
              comment: '',
            },
            {
              name: 'Line2',
              phaseActivity: true,
              propertyParams: {
                ux: 0,
              },
              comment: '',
            },
            {
              name: 'Line5',
              phaseActivity: true,
              propertyParams: {
                ux: 0,
              },
              comment: '',
            },
            {
              name: 'Line6',
              phaseActivity: true,
              propertyParams: {
                ux: 0,
              },
              comment: '',
            },
          ],
        },
      },

      propertiesData: {
        linesProperties: [
          {
            Line1: {
              plateProperty: false,
              loadProperty: false,
              spacerProperty: false,
              boundaryCondition: true,
            },
          },
          {
            Line2: {
              plateProperty: false,
              loadProperty: false,
              spacerProperty: false,
              boundaryCondition: true,
            },
          },
          {
            Line5: {
              plateProperty: false,
              loadProperty: false,
              spacerProperty: false,
              boundaryCondition: true,
            },
          },
          {
            Line6: {
              plateProperty: false,
              loadProperty: false,
              spacerProperty: false,
              boundaryCondition: true,
            },
          },
        ],
        polygonsProperties: [
          {
            Surface1: 'material',
          },
          {
            Surface2: 'material',
          },
        ],
      },

      characteristicsData: {
        oneDimData: [],
        twoDimData: [
          {
            грунт1: {
              weight: 20,
              poisson: 0.3,
              mechParameter: 'linear-elastic',
              elasticModulus: 20000,
            },
          },
          {
            фундамент: {
              weight: 250,
              poisson: 0.15,
              mechParameter: 'linear-elastic',
              elasticModulus: 10000000,
            },
          },
        ],
      },
    };
  },
  mutations: {
    setIsUpdated(state, payload) {
      state.isUpdated = payload.isUpdated;
    },
    setIsLoading(state, payload) {
      state.isLoading = payload.isLoading;
    },
    showToast(_, payload) {
      toastr.options.progressBar = true;
      toastr.options.positionClass = 'toast-bottom-right';
      switch (payload.type) {
        case 'ok':
          toastr.success(payload.msg);
          break;
        case 'error':
          toastr.error(payload.msg);
          break;
        case 'warning':
          toastr.warning(payload.msg);
          break;
        case 'info':
          toastr.info(payload.msg);
          break;
      }
    },
    setTaskType(state, payload) {
      state.taskType = payload.taskType;
    },
    setData(state, payload) {
      state.jsonData = payload.jsonData;
      state.calculatedSchemeData = payload.calculatedSchemeData;
    },
    setLinesData(state, payload) {
      state.linesData = payload.linesData;
    },
    setPolygonsData(state, payload) {
      state.polygonsData = payload.polygonsData;
    },
    setCoordsData(state, payload) {
      state.coordsData = payload.coordsData;
    },
    setStageData(state, payload) {
      state.stageData = payload.stageData;
    },
    setPropertiesData(state, payload) {
      state.propertiesData = payload.propertiesData;
    },
    setCharacteristicsData(state, payload) {
      state.characteristicsData = payload.characteristicsData;
    },
  },
  actions: {
    sendIsLoading(context, payload) {
      context.commit('setIsLoading', {
        isLoading: payload.isLoading,
      });
    },
    sendIsUpdated(context, payload) {
      context.commit('setIsUpdated', {
        isUpdated: payload.isUpdated,
      });
    },
    sendToast(context, payload) {
      context.commit('showToast', payload.toastInfo);
    },
    sendTaskType(context, payload) {
      context.commit('setTaskType', { taskType: payload.taskType });
    },
    sendDataFromFile(context, payload) {
      context.commit('setData', {
        jsonData: payload.jsonData,
        calculatedSchemeData: payload.calculatedSchemeData,
      });
    },
    sendLinesData(context, payload) {
      context.commit('setLinesData', { linesData: payload.linesData });
    },
    sendPolygonsData(context, payload) {
      context.commit('setPolygonsData', { polygonsData: payload.polygonsData });
    },
    sendCoordsData(context, payload) {
      context.commit('setCoordsData', { coordsData: payload.coordsData });
    },
    sendStageData(context, payload) {
      context.commit('setStageData', { stageData: payload.stageData });
    },
    sendPropertiesData(context, payload) {
      context.commit('setPropertiesData', {
        propertiesData: payload.propertiesData,
      });
    },
    sendCharacteristicsData(context, payload) {
      context.commit('setCharacteristicsData', {
        characteristicsData: payload.characteristicsData,
      });
    },
  },
  getters: {
    taskType(state) {
      return state.taskType;
    },
    gmshData(state) {
      return state.jsonData;
    },
    calculatedSchemeData(state) {
      return state.calculatedSchemeData;
    },
    linesData(state) {
      return state.linesData;
    },
    polygonsData(state) {
      return state.polygonsData;
    },
    coordsData(state) {
      return state.coordsData;
    },
    stageData(state) {
      return state.stageData;
    },
    propertiesData(state) {
      return state.propertiesData;
    },
    characteristicsData(state) {
      return state.characteristicsData;
    },
    isUpdated(state) {
      return state.isUpdated;
    },
    isLoading(state) {
      return state.isLoading;
    },
  },
});

export default store;
